@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  font-family: "Ruberoid", sans-serif;
  font-weight: 400;
}
html {
  scroll-behavior: smooth;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

@font-face {
  font-family: "Ruberoid";
  src: url("./assets/fonts/Ruberoid-Regular.otf") format("opentype"); /* Adjust the path as needed */
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Ruberoid";
  src: url("./assets/fonts/Ruberoid-Bold.otf") format("opentype"); /* Adjust the path as needed */
  font-weight: bold;
  font-style: normal;
}
.verSwiper {
  width: 100%;
}

.swiper-slide {
  text-align: center;
  font-size: 18px;
  /* background: #fff; */

  /* Center slide text vertically */
  display: flex;
  justify-content: center;
  align-items: center;
}

.swiper-pagination-bullet-active {
  background: white !important;
}
.verSwiper .swiper-button-next,
.verSwiper .swiper-button-prev {
  display: none;
}
.bg-gradient {
  background: radial-gradient(circle, rgba(255, 255, 255, 0.2) 0%, rgba(255, 255, 255, 0) 70%);
}

.marginTop {
  @apply mt-5 sm:mt-8 md:mt-10 lg:mt-10 xl:mt-16 2xl:mt-24;
}

.discountSwiper .swiper-button-next,
.discountSwiper .swiper-button-prev {
  color: white;
  scale: 0.5;
}

@media screen and (max-width: 768px) {
  .discountSwiper .swiper-button-next,
  .discountSwiper .swiper-button-prev {
    display: none;
  }
}


.thumbs{
  max-width: 350px;
  /* height: 300px; */
  height: 100%;
  
}

.thumbs .swiper {
  width: 100%;
  height: 100%;
}

.thumbs .swiper-slide {
  text-align: center;
  font-size: 18px;
  background: #fff;

  /* Center slide text vertically */
  display: flex;
  justify-content: center;
  align-items: center;
}
.thumbs .swiper-horizontal{
  display: flex;
  justify-content: center;
  align-items: center;
}

.thumbs .swiper-slide img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}



.thumbs .swiper {
  width: 100%;
  height: 300px;
  margin-left: auto;
  margin-right: auto;
}

.thumbs .swiper-slide {
  background-size: cover;
  background-position: center;
}

.thumbs .mySwiper2 {
  height: 80%;
  width: 100%;
}

.thumbs .mySwiper {
  height: 20%;
  box-sizing: border-box;
  padding: 10px 0;
}

.thumbs .mySwiper .swiper-slide {
  width: 25%;
  height: 100%;
  opacity: 0.4;
}

.thumbs .mySwiper .swiper-slide-thumb-active {
  opacity: 1;
}
.thumbs .swiper-button-next::after,
.thumbs .swiper-button-prev::after{
  font-size: 18px;
  font-weight: 900;
}
.thumbs .swiper-button-next,
.thumbs .swiper-button-prev{
  width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: white;
  border-radius: 50%;
}
.thumbs .swiper-slide img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}